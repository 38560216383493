import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import AvatarImage from '@shared/ui/display/AvatarImage';
import withPercentageIncomeBadge from '@shared/ui/badges/withPercentageIncomeBadge';
import { ContainerRow } from '@components/styled';
import { BodyMediumSemiBold, BodySmallSemiBold } from '@components/styled/Typography';
import { numberToFixed2 } from '@utils/DisplayValueHelpers';
import { TooltipElementWidth } from '../constants';
const BadgeText = styled(BodySmallSemiBold)(() => ({
    lineHeight: '18px',
}));
const IncomeBadge = withPercentageIncomeBadge(BadgeText);
const TooltipElementContainer = styled(ContainerRow, { shouldForwardProp: (propName) => propName !== 'borderColor' })((props) => ({
    maxWidth: TooltipElementWidth,
    boxSizing: 'border-box',
    height: 36,
    alignItems: 'center',
    gap: props.theme.spacing_sizes.xm,
    borderRadius: 6,
    backgroundColor: '#11181CBF',
    borderLeft: `6px solid ${props.borderColor}`,
    padding: `${props.theme.spacing_sizes.xs}px ${props.theme.spacing_sizes.m}px`,
}));
const TooltipNameContainer = styled(ContainerRow)(props => ({
    height: 'auto',
    gap: props.theme.spacing_sizes.xs * 0.25,
}));
const TooltipPortfolioName = styled(BodyMediumSemiBold)(() => ({
    maxWidth: 56,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    margin: 0,
}));
const TooltipInfo = styled(ContainerRow)(props => ({
    gap: props.theme.spacing_sizes.s,
}));
const TooltipAmount = styled(BodyMediumSemiBold)(() => ({
    whiteSpace: 'nowrap',
}));
const AvatarWrapper = styled.div(() => ({
    minWidth: 20,
    maxWidth: 20,
    pointerEvents: 'none',
}));
const defineStyles = (isPositive = false) => (isPositive ? ({
    backgroundColor: 'rgba(18, 55, 37, 0.75)',
    color: '#64E793',
}) : ({
    backgroundColor: '#381C1D',
    color: '#FF6368',
}));
const TooltipElement = ({ percent, portfolioName, amount, avatarImage = undefined, borderColor = undefined, showPercentage = undefined, }) => {
    const styles = defineStyles(percent >= 0);
    return (_jsxs(TooltipElementContainer, { borderColor: borderColor, children: [_jsxs(TooltipInfo, { children: [_jsx(AvatarWrapper, { children: _jsx(AvatarImage, { imageUrl: avatarImage, publicName: portfolioName, size: 'avatarSwitcher' }) }), _jsxs(TooltipNameContainer, { children: [_jsx(TooltipPortfolioName, { children: portfolioName }), showPercentage && (_jsx(IncomeBadge, { style: styles, badgeSize: 'small', badgeVariant: 'tinted', percentageIncome: percent }))] })] }), _jsx(TooltipAmount, { children: `${numberToFixed2(amount)} USDT` })] }));
};
export default TooltipElement;
